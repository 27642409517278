import '../../index.css';
import '../../utils.css';

import React from 'react';

import { sanitize } from '../utils';
import { FeatureSidebar } from './FeatureSidebar';
import { storyMetas } from './NavSidebar';
import s from './Preview.module.css';
import { Meta } from './types';

export const Preview = ({ id }: { id: string }) => {
  const meta = storyMetas.find((meta) => sanitize(meta.title) === id) as Meta;
  const [storyState, setStoryState] = React.useState(meta.config.args);

  const ComponentRaw = meta.component || (() => <div>Loading...</div>);

  const Component = (() => {
    let currComp: React.FC = ComponentRaw;
    for (const decorator of meta.config.decorators) {
      currComp = decorator(currComp, storyState);
    }
    return currComp;
  })();

  return (
    <div className={s.layout}>
      <div className={s.container}>
        <Component />
      </div>
      <FeatureSidebar
        setStoryState={setStoryState}
        storyState={storyState}
        meta={meta}
      />
    </div>
  );
};
