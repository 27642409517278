/* eslint-disable i18next/no-literal-string */

import { featureDetails, Features, Theme } from '@cutr/constants/cutlist-theme';

import { Checkbox } from '@/primitives/Checkbox';
import { Input, Textarea } from '@/primitives/Input';
import { Select } from '@/primitives/Select';
import { getFeaturesForTheme } from '@/theme';

import s from './FeatureSidebar.module.css';
import { Args, liveThemes, Meta } from './types';

type FeatureSidebarProps = {
  meta: Meta;
  storyState: Args;
  setStoryState: (storyState: Args) => void;
};

export const FeatureSidebar = ({
  meta,
  storyState,
  setStoryState,
}: FeatureSidebarProps) => {
  const featureComponents = Object.keys(storyState)
    .map((featureKey) => {
      const key = featureKey as keyof Features;
      const featureType = meta.argTypes[key];
      if (!featureType) return null;

      const details = featureDetails[key];
      if (!details) {
        console.error(
          "This feature doesn't have details yet, please consider adding details.",
          { key }
        );
        return;
      }

      if (!details.scope.includes('/parts')) {
        return;
      }

      let Component = null;
      switch (featureType.control) {
        case 'boolean':
          Component = (
            <Checkbox
              key={key}
              checked={Boolean(storyState[key])}
              onChange={() => {
                setStoryState({ ...storyState, [key]: !storyState[key] });
              }}
            >
              {key}
            </Checkbox>
          );
          break;
        case 'number':
          Component = (
            <div>
              <label>{key}</label>
              <Input
                key={key}
                value={String(storyState[key])}
                type="number"
                onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setStoryState({
                    ...storyState,
                    [key]: Number(e.target.value),
                  });
                }}
              />
            </div>
          );
          break;
        case 'select':
          Component = (
            <div>
              <label>{key}</label>
              <Dropdown
                key={key}
                options={featureType.options}
                selected={(storyState[key] as string[])[0] || ''}
                onChange={(e) => {
                  {
                    setStoryState({
                      ...storyState,
                      [key]: e.currentTarget.value,
                    });
                  }
                }}
              />
            </div>
          );
          break;
        case 'text':
          Component = (
            <div>
              <label>{key}</label>
              <Textarea
                key={key}
                value={JSON.stringify(storyState[key])}
                rows={6}
                onInput={(e) => {
                  setStoryState({
                    ...storyState,
                    [key]: JSON.parse(e.currentTarget.value),
                  });
                }}
              />
            </div>
          );
          break;
        default:
          break;
      }
      return <div key={key}>{Component}</div>;
    })
    .filter(Boolean);
  return (
    <aside className={s.navWrapper}>
      <h2>Partners</h2>
      <Dropdown
        options={liveThemes}
        selected={storyState.theme}
        onChange={(e) => {
          const theme = e.currentTarget.value as Theme;
          setStoryState({
            ...getFeaturesForTheme(theme),
            theme,
          });
        }}
      />
      <h2>Features</h2>
      <div className={s.features}>{...featureComponents}</div>
    </aside>
  );
};

type DropdownProps = {
  options: string[];
  selected: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
};

const Dropdown = ({ options, selected, onChange }: DropdownProps) => {
  const selectOptions = options.map?.((option) => ({
    value: option,
    label: option,
    selected: selected === option,
  }));
  return <Select options={selectOptions} onChange={onChange} />;
};
