import { Features, Theme } from '@cutr/constants/cutlist-theme';

export const CONFIGURATOR_ROUTE = 'configurator';

export const liveThemes: Theme[] = ['cutr', 'bnb', 'stiho', 'verdouw'];

export type Meta = {
  title: string;
  component: () => JSX.Element;
  argTypes: Record<keyof Features, ArgType>;
  config: {
    args: Args;
    decorators: ((story: React.FC, context: Args) => React.FC)[];
  };
};

export type Args = {
  theme: Theme;
  isEnabled?: boolean;
} & Features;

export type ArgType =
  | { control: 'boolean' }
  | { control: 'number' }
  | { control: 'select'; options: string[] }
  | { control: 'text' };
