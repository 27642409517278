/* eslint-disable i18next/no-literal-string */
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { resetErrors } from '@/api/errors';
import { useAuthStore } from '@/api/login';
import { useMaterialGroupState } from '@/api/materialsGroup';
import { useNestingStore } from '@/api/nesting';
import { usePricingStore } from '@/api/pricing';
import { useCutlistState } from '@/api/store';
import { Button } from '@/primitives/Button';
import { Icon, Logout } from '@/primitives/Icons';

import OrderPageFeatures from '../OrderPageFeatures';
import { sanitize } from '../utils';
import s from './NavSidebar.module.css';
import { CONFIGURATOR_ROUTE, Meta } from './types';

export const storyMetas: Meta[] = [OrderPageFeatures];

export const NavSidebar = () => {
  return (
    <aside className={s.navWrapper}>
      <div>
        <a
          href={`/${CONFIGURATOR_ROUTE}`}
          style={{ textDecoration: 'none', color: 'currentcolor' }}
        >
          <h1>Cutlist configurator</h1>
        </a>
        <br />
        <ul>
          {storyMetas.map((meta) => (
            <li key={meta.title}>
              <Link to={`/${CONFIGURATOR_ROUTE}/${sanitize(meta.title)}`}>
                {meta.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <LogOut />
    </aside>
  );
};

const LogOut = () => {
  const { logout, clientNumber, email } = useAuthStore();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const priceStore = usePricingStore();
  const nestingStore = useNestingStore();

  const cutlistState = useCutlistState();
  const groupStore = useMaterialGroupState();

  const onLogout = () => {
    logout();

    resetErrors();
    priceStore.reset();
    groupStore.clear();
    cutlistState.reset();
    nestingStore.setNesting([]);

    navigate('/');
  };

  return (
    <Button
      className="tooltip tooltip"
      variant="secondary"
      icon={<Icon icon={<Logout />} />}
      onClick={onLogout}
      data-tooltip={email + ' ' + clientNumber}
    >
      {t('common.logout')}
    </Button>
  );
};
