import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'sonner';

import { ApiError } from '@/api/backend';
import { Modal } from '@/blocks/Modal';
import { onFocusSelect } from '@/hooks';
import { Button } from '@/primitives/Button';
import { Input } from '@/primitives/Input';

interface AgentDiscountModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AgentDiscountModal: React.FC<AgentDiscountModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  const [discountType, setDiscountType] = useState<'percentage' | 'amount'>(
    'percentage'
  );
  const [discountValue, setDiscountValue] = useState<number>(1);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    const data = new FormData(e.currentTarget);
    console.log(data);
    try {
      setLoading(true);
      // const cutlist = await assignCutlistOwner(email as string);

      toast.success('Discount set successfully');
      setLoading(false);
      onClose();
    } catch (error: unknown) {
      if (error instanceof ApiError) {
        toast.error(error.message);
      } else {
        toast.error('An unexpected error occurred');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={t(`agent.discount.modalTitle`)}
      handleClose={onClose}
      isOpen={isOpen}
    >
      <div className="stack">
        <p>{t(`agent.discount.description`)}</p>
        <form
          style={{ display: 'grid', gap: 'var(--space-l)' }}
          onSubmit={onSubmit}
        >
          <DiscountTypeRadio
            discountType={discountType}
            setDiscountType={(type) => {
              setDiscountValue(0);
              setDiscountType(type);
            }}
          />
          {discountType === 'percentage' && (
            <DiscountValuePercentage
              discountValue={discountValue}
              setDiscountValue={setDiscountValue}
            />
          )}
          {discountType === 'amount' && (
            <DiscountValueAmount
              discountValue={discountValue}
              setDiscountValue={setDiscountValue}
            />
          )}
          <Button type="submit" disabled={loading}>
            {t(`agent.discount.submit`)}
          </Button>
        </form>
      </div>
    </Modal>
  );
};

const DiscountTypeRadio = ({
  discountType,
  setDiscountType,
}: {
  discountType: 'percentage' | 'amount';
  setDiscountType: (type: 'percentage' | 'amount') => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="stack">
      <strong>{t(`agent.discount.type`)}</strong>
      <div className="flexAlign">
        <div className="flexAlign gap-xs">
          <input
            name="discount-type"
            id="discount-percentage"
            type="radio"
            value="percentage"
            style={{
              appearance: 'auto',
              margin: 0,
              accentColor: 'var(--primary)',
            }}
            onChange={() => setDiscountType('percentage')}
            checked={discountType === 'percentage'}
            required
          />
          <label htmlFor="discount-percentage">
            {t(`agent.discount.percentage`)}
          </label>
        </div>
        <div className="flexAlign gap-xs">
          <input
            name="discount-type"
            id="discount-amount"
            type="radio"
            value="amount"
            style={{
              appearance: 'auto',
              margin: 0,
              accentColor: 'var(--primary)',
            }}
            onChange={() => setDiscountType('amount')}
            checked={discountType === 'amount'}
          />
          <label htmlFor="discount-amount">{t(`agent.discount.amount`)}</label>
        </div>
      </div>
    </div>
  );
};

const DiscountValueAmount = ({
  discountValue,
  setDiscountValue,
}: {
  discountValue: number;
  setDiscountValue: (value: number) => void;
}) => {
  const { t } = useTranslation();

  return (
    <div className="stack">
      <strong>{t(`agent.discount.amountLabel`)}</strong>
      <div className="flexAlign">
        <div className="flexAlign gap-xs">
          <Input
            name="discount-value-amount"
            id="discount-value-amount"
            type="number"
            min={0}
            step={0.01}
            style={{ textAlign: 'right', maxWidth: '5rem' }}
            value={discountValue}
            onFocus={onFocusSelect}
            onChange={(e) => setDiscountValue(Number(e.target.value))}
          />
          <span>{'€'}</span>
        </div>
      </div>
    </div>
  );
};

const DiscountValuePercentage = ({
  discountValue,
  setDiscountValue,
}: {
  discountValue: number;
  setDiscountValue: (value: number) => void;
}) => {
  const { t } = useTranslation();
  console.log(discountValue);
  return (
    <div className="stack">
      <strong>{t(`agent.discount.percentageLabel`)}</strong>
      <div className="flexAlign">
        <div className="flexAlign gap-xs">
          <Input
            name="discount-value-percentage"
            id="discount-value-percentage"
            type="number"
            min={1}
            max={100}
            step={0.01}
            style={{ textAlign: 'right', minWidth: '5rem' }}
            value={discountValue}
            onFocus={onFocusSelect}
            onChange={(e) => setDiscountValue(Number(e.target.value))}
          />
          <span>%</span>
        </div>
      </div>
    </div>
  );
};

export default AgentDiscountModal;
